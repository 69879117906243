export default {
  name: 'BrandLogo',
  props: {
    brand: {
      validator: (e) => ['vestre'].includes(e.toLowerCase()),
      required: true
    },
    size: {
      type: String,
      default: '20'
    }
  },
  render: function (createElement) {
    require(`@/assets/logos/${this.brand.toLowerCase()}`)
    if (!this.brand) {
      return null
    }
    return createElement(
      'svgicon',
      {
        props: {
          name: `${this.brand.toLowerCase()}_logo`,
          width: this.size,
          height: this.size
        }
      }
    )
  }
}
