<template>
  <v-card
    ref="card"
    style="overflow-y: auto; height: 100%;"
    elevation="0"
  >
    <v-data-table
      :headers="headers"
      :items="templates"
      :items-per-page="100"
      :search="search"
      :loading="loading"
      group-by="suggestionsCategory"
      sort-by="impactRanking"
      class="px-3"
      @current-items="handleItemsChange"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="headline pb-0 pt-2">
            Templates
          </v-toolbar-title>
          <v-text-field
            v-model="search"
            class="mx-5 pt-2"
            append-icon="mdi-magnify"
            label="Filter templates"
            single-line
            hide-details
            style="max-width: 300px;"
          />
          <v-spacer />
          <v-btn
            color="shamrock"
            dark
            class="mb-2 mr-5"
            outlined
            elevation="0"
            href="/admin/templates/products/new"
          >
            New product template
            <v-icon right>
              mdi-store-plus
            </v-icon>
          </v-btn>
          <v-btn
            color="shamrock"
            dark
            class="mb-2"
            outlined
            elevation="0"
            href="/admin/templates/new"
          >
            New template
            <v-icon right>
              mdi-book-plus-outline
            </v-icon>
          </v-btn>
        </v-toolbar>
        <div class="caption shamrock--text mx-4 pt-3">
          Elements are grouped by category, ordered from lowest carbon impact to highest.
        </div>
        <div class="mx-4">
          <v-checkbox
            v-model="showProductsOnly"
            label="Show products only"
            color="shamrock"
          />
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        <div class="d-flex align-center">
          <BrandLogo
            v-if="item.manufacturer"
            :brand="item.manufacturer"
            size="32"
            class="mr-4"
            style="background: yellow;"
          />
          <span :style="{ 'font-weight': item.manufacturer ? '500' : '400' }">
            {{ item.name }}
          </span>
        </div>
      </template>
      <template v-slot:item.formula="{ item }">
        {{ item.formulaForOneTime || item.formulaForGivenYear || 'sink formula (edit for details)' }}
      </template>
      <template v-slot:item.actions="{ item }">
        <EditAndDeleteButtons
          :edit-href="item.manufacturer ? `/admin/templates/products/${item._id}` : `/admin/templates/${item._id}`"
          @delete="requestDelete(item)"
        />
      </template>
      <template #group.header="{ group }">
        <td
          class="white title font-weight-light pt-5 category-header"
          colspan="6"
        >
          <span
            class="text-capitalize pl-2"
            style="border-left: 2px solid var(--v-metal-base);"
          >
            {{ group }}
          </span>
        </td>
      </template>
      <template #item.handle>
        <v-tooltip top>
          <template #activator="{ on }">
            <div
              class="row-handle"
              v-on="on"
            >
              <v-icon>mdi-drag-vertical</v-icon>
            </div>
          </template>
          Reorder item
        </v-tooltip>
      </template>
    </v-data-table>
    <ConfirmDialog
      :question="`Delete ${deletingItem ? deletingItem.name : 'template'}?`"
      details="This action is permanent"
      :show="showConfirmDeleteDialog"
      confirm-color="crimson"
      confirm-text="Delete"
      @cancel="showConfirmDeleteDialog = false"
      @confirm="deleteTemplate(deletingItem._id)"
    />
  </v-card>
</template>

<script>
import ConfirmDialog from '@/components/atoms/ConfirmDialog'
import Sortable from 'sortablejs'
import EditAndDeleteButtons from '@/components/tables/EditAndDeleteButtons.vue'

import BrandLogo from '@/components/atoms/BrandLogo'

export default {
  name: 'TemplateList',
  components: {
    ConfirmDialog,
    BrandLogo,
    EditAndDeleteButtons
  },
  data () {
    return {
      showProductsOnly: false,
      search: '',
      showConfirmDeleteDialog: false,
      deletingItem: null,
      loading: false,
      headers: [
        {
          sortable: false,
          text: '',
          value: 'handle',
          width: 20
        },
        {
          sortable: true,
          text: 'Name',
          value: 'name'
        },
        {
          sortable: true,
          text: 'CPD Version',
          value: 'cpdVersion'
        },
        {
          sortable: false,
          text: 'Manufacturer',
          value: 'manufacturer'
        },
        {
          sortable: false,
          text: 'Category',
          value: 'category'
        },
        {
          sortable: false,
          text: 'Element Type',
          value: 'type'
        },
        {
          sortable: false,
          text: 'CO₂e Measured Per',
          value: 'co2MeasuredPer'
        },
        {
          sortable: false,
          text: 'Replacements',
          value: 'replacements'
        },
        {
          sortable: false,
          text: 'CO₂e formula',
          value: 'formula'
        },
        {
          sortable: false,
          text: 'Carbon impact ranking (with 1 being most climate positive)',
          value: 'impactRanking'
        },
        {
          sortable: false,
          value: 'actions',
          align: 'right'
        }
      ],
      templates: [],
      templatesAsDisplayedInTable: null
    }
  },
  watch: {
    showProductsOnly () {
      this.fetchTemplates()
    }
  },
  created () {
    this.fetchTemplates()
  },
  methods: {
    async fetchTemplates () {
      this.loading = true
      const { data: templates } = await this.$axios.get(`/templates?products-only=${this.showProductsOnly ? 1 : 0}`)
      this.templates = []
      this.$nextTick(() => {
        this.templates = templates.map(template => ({ ...template, impactRanking: template.impactRanking ? template.impactRanking.toString() : '' }))
        if (this.showProductsOnly) {
          this.templates = this.templates.filter((x) => x.manufacturer)
        }
        this.$nextTick(() => {
          this.setupSorting()
          this.loading = false
        })
      })
    },
    handleItemsChange (items) {
      this.templatesAsDisplayedInTable = items
    },
    setupSorting () {
      const tableBody = this.$refs.card.$el.querySelector('.v-data-table tbody')
      for (let i = 0; i < tableBody.children.length; i++) {
        const row = tableBody.children[i]
        if (!row.matches('.v-row-group__header')) {
          row.classList.add('draggable')
        }
      }
      const _self = this
      Sortable.create(tableBody, {
        handle: '.row-handle',
        draggable: '.draggable',
        async onEnd ({ newDraggableIndex, oldDraggableIndex }) {
          // work out the categories shown in table
          const element = _self.templatesAsDisplayedInTable[oldDraggableIndex]
          const otherTemplatesInCategory = _self.templatesAsDisplayedInTable.filter(template => template.suggestionsCategory === element.suggestionsCategory)
          const spaceToMoveBack = otherTemplatesInCategory.filter(template => template.impactRanking < element.impactRanking).length
          const spaceToMoveForward = otherTemplatesInCategory.filter(template => template.impactRanking > element.impactRanking).length
          const spacesMoved = newDraggableIndex - oldDraggableIndex
          if (spacesMoved > spaceToMoveForward || spacesMoved < (spaceToMoveBack * -1)) {
            _self.$store.dispatch('showSnackbar', { color: 'error', text: 'Cannot drag to switch template category. To change category, click edit template.' })
            _self.fetchTemplates()
            return
          }
          await _self.$axios.put(`/templates/${element._id}/impact-ranking`, {
            spacesMoved
          })
          setTimeout(() => {
            _self.fetchTemplates()
          })
        }
      })
    },
    requestDelete (item) {
      this.showConfirmDeleteDialog = true
      this.deletingItem = item
    },
    async deleteTemplate (id) {
      try {
        await this.$axios.delete(`/templates/${id}`)
        this.fetchTemplates()
        this.showConfirmDeleteDialog = false
      } catch (err) {
        this.showSnackbar({ color: 'error', message: 'Unable to delete template. Please try again.' })
        throw err
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .row-handle {
    cursor: move !important;
    cursor: -webkit-grabbing !important;
  }
</style>
