var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{ref:"card",staticStyle:{"overflow-y":"auto","height":"100%"},attrs:{"elevation":"0"}},[_c('v-data-table',{staticClass:"px-3",attrs:{"headers":_vm.headers,"items":_vm.templates,"items-per-page":100,"search":_vm.search,"loading":_vm.loading,"group-by":"suggestionsCategory","sort-by":"impactRanking"},on:{"current-items":_vm.handleItemsChange},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"headline pb-0 pt-2"},[_vm._v(" Templates ")]),_c('v-text-field',{staticClass:"mx-5 pt-2",staticStyle:{"max-width":"300px"},attrs:{"append-icon":"mdi-magnify","label":"Filter templates","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 mr-5",attrs:{"color":"shamrock","dark":"","outlined":"","elevation":"0","href":"/admin/templates/products/new"}},[_vm._v(" New product template "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-store-plus ")])],1),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"shamrock","dark":"","outlined":"","elevation":"0","href":"/admin/templates/new"}},[_vm._v(" New template "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-book-plus-outline ")])],1)],1),_c('div',{staticClass:"caption shamrock--text mx-4 pt-3"},[_vm._v(" Elements are grouped by category, ordered from lowest carbon impact to highest. ")]),_c('div',{staticClass:"mx-4"},[_c('v-checkbox',{attrs:{"label":"Show products only","color":"shamrock"},model:{value:(_vm.showProductsOnly),callback:function ($$v) {_vm.showProductsOnly=$$v},expression:"showProductsOnly"}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.manufacturer)?_c('BrandLogo',{staticClass:"mr-4",staticStyle:{"background":"yellow"},attrs:{"brand":item.manufacturer,"size":"32"}}):_vm._e(),_c('span',{style:({ 'font-weight': item.manufacturer ? '500' : '400' })},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"item.formula",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.formulaForOneTime || item.formulaForGivenYear || 'sink formula (edit for details)')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('EditAndDeleteButtons',{attrs:{"edit-href":item.manufacturer ? ("/admin/templates/products/" + (item._id)) : ("/admin/templates/" + (item._id))},on:{"delete":function($event){return _vm.requestDelete(item)}}})]}},{key:"group.header",fn:function(ref){
var group = ref.group;
return [_c('td',{staticClass:"white title font-weight-light pt-5 category-header",attrs:{"colspan":"6"}},[_c('span',{staticClass:"text-capitalize pl-2",staticStyle:{"border-left":"2px solid var(--v-metal-base)"}},[_vm._v(" "+_vm._s(group)+" ")])])]}},{key:"item.handle",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"row-handle"},on),[_c('v-icon',[_vm._v("mdi-drag-vertical")])],1)]}}])},[_vm._v(" Reorder item ")])]},proxy:true}])}),_c('ConfirmDialog',{attrs:{"question":("Delete " + (_vm.deletingItem ? _vm.deletingItem.name : 'template') + "?"),"details":"This action is permanent","show":_vm.showConfirmDeleteDialog,"confirm-color":"crimson","confirm-text":"Delete"},on:{"cancel":function($event){_vm.showConfirmDeleteDialog = false},"confirm":function($event){return _vm.deleteTemplate(_vm.deletingItem._id)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }